import * as React from 'react';
import "./Carroucel.css";
import IMG1 from '../../assets/twigg/Tw1.jpeg';
import G1 from '../../assets/Galeria de Fotos/G1.jpeg'
import G2 from '../../assets/Galeria de Fotos/G2.jpeg'
import G3 from '../../assets/Galeria de Fotos/G3.jpeg'
import G4 from '../../assets/Galeria de Fotos/G4.jpeg'
import G5 from '../../assets/Galeria de Fotos/G5.jpeg'
import G6 from '../../assets/Galeria de Fotos/G6.jpeg'


export default function Carroucel() {
    return (
        <div>
            <div class="slider">
                <div class="slide-track">
                    <div class="slide">
                        <img src={G1} />
                    </div>
                    <div class="slide">
                        <img src={G2} />
                    </div>
                    <div class="slide">
                        <img src={IMG1} />
                    </div>
                    <div class="slide">
                        <img src={G3} />
                    </div>
                    <div class="slide">
                        <img src={G4} />
                    </div>
                    <div class="slide">
                        <img src={G5} />
                    </div>
                    <div class="slide">
                        <img src={G6} />
                    </div>
                    <div class="slide">
                        <img src={G5} />
                    </div>
                    <div class="slide">
                        <img src={G1} />
                    </div>
                    <div class="slide">
                        <img src={G2} />
                    </div>
                    <div class="slide">
                        <img src={IMG1} />
                    </div>
                    <div class="slide">
                        <img src={G3} />
                    </div>

                </div>
            </div>
        </div>
    )
}
