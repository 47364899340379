import React, { useEffect } from "react";
import "./Features.css";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import condometro from "../../assets/SoftwareProjects/condometro.png";
import Feature from "./Feature";
import { Condometro_Description } from "./data";

import AOS from "aos";
import "aos/dist/aos.css";

const Condometro = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="container features">
      <div className="title" data-aos="fade-up">
        <BsFillBookmarkStarFill color="orangered" size={30} />
        <h2>CONDOMETRO</h2>
        <p className="u-text-small">A condominium management app</p>
      </div>
      <div className="features-content">
        <div className="features-left" data-aos="fade-right">
          <div className="caracteristicas-condometro">
            <img src={condometro} alt="phone" />
          </div>
        </div>
        <div className="features-right" data-aos="fade-left">
          {Condometro_Description.map((feature) => (
            <Feature
              key={feature.id}
              icon={feature.icon}
              heading={feature.heading}
              text={feature.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Condometro;
