import React, { useEffect } from "react";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import { FaLaptopCode } from "react-icons/fa";
import Treinamento1 from "../../../assets/twigg/Tw3.jpeg";

import AOS from "aos";
import "aos/dist/aos.css";
import Aboutus from "../../Aboutus/Aboutus";

const ServicesDescription = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="features">
      <Aboutus />
    </section>
  );
};

export default ServicesDescription;
