import React, { useEffect } from "react";
import "./HeaderProducs.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import DevlopmentServices from "../../assets/headers/Software Dev.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const HeaderTechno = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="header">
      <div className="container headerr">
        <div className="header-left" data-aos="fade-right">
          <h1>
            <span>Software Devlopment</span>
          </h1>
          <h2>
            <span>Check out our technological solutions</span>
          </h2>

          <div className="descri">
            <p>Trust Speranza to develop your company's technological needs!</p>
          </div>
          <div className="header-cta">
            <Button
              text={"See Now"}
              btnClass={"btn-light"}
              href={"#features"}
            />
          </div>
        </div>
        <div className="header-right" data-aos="fade-left">
          <img src={DevlopmentServices} alt="phone" />
        </div>
      </div>
    </section>
  );
};

export default HeaderTechno;
