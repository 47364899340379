import React from "react";
import { FaAccessibleIcon, FaGg } from "react-icons/fa";
import { SiDatabricks } from "react-icons/si";
import { MdConnectWithoutContact } from "react-icons/md";

export const FeatureList = [
  {
    id: 1,
    // icon: <FaAccessibleIcon color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Tax payment",
    text: "The system allows you to pay taxes.",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Document Consultation",
    text:
      "The system allows citizens to consult the status of their documents such as DUATs, among others, without having to leave their homes.",
  },
  {
    id: 3,
    // icon: <MdConnectWithoutContact color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Useful information",
    text:
      "The system allows citizens or tourists to know, without having to travel, several useful information about the Municipality, namely: Schools, Restaurants, Clinics, among others.",
  },
  {
    id: 4,
    // icon: <FaGg color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Payment of parking and market fees",
    text:
      "The system allows residents to pay parking fees for rotating parking, among others, as well as allowing vendors in the markets to pay control of their daily fees payable..",
  },
];

export const Bages = [
  {
    id: 1,
    // icon: <FaAccessibleIcon color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Designed for your Company",
    text: "A nice looking app that matches the image of your Company",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Badge Module",
    text:
      "With the system, it is possible to generate badges for your employees and print them, wherever and whenever you want, as long as you are connected to the internet.",
  },
  {
    id: 3,
    // icon: <MdConnectWithoutContact color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Training Scheduling Module",
    text:
      "The system has a module for training management, where supervisors can schedule training for company employees and better control who has or has not had training.",
  },
];

export const Condometro_Description = [
  {
    id: 1,
    // icon: <FaAccessibleIcon color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Designed for you",
    text: "A nice looking app that matches the image of your condominium",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Expense Management Module",
    text:
      "The application has an expense management module that allows the administrator or application manager to create and allocate expenses to a specific member of the condominium.",
  },
  {
    id: 3,
    // icon: <MdConnectWithoutContact color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Operation Reports",
    text:
      "Generate reports around expenses or expenses of the condominium for its better management.",
  },
  {
    id: 4,
    // icon: <FaGg color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Notifications System",
    text:
      "Notify your tenants through notifications about expenses or fees payable.",
  },
  {
    id: 5,
    // icon: <FaGg color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Chat System",
    text:
      "The application has a feature that allows members of the condominium to interact around issues related to the condominium as well as transmit messages to the members of the condominium.",
  },
];

export const Atendimento_data = [
  {
    id: 1,
    // icon: <FaAccessibleIcon color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Designed for you",
    text: "A nice looking app that matches the image of your condominium",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Graphic summary and comments at the discretion of the manager",
    text:
      "Which pending issues need to be followed up by the responsible team? What aspects most concern your customers? You can get the visual summary for the period you want and define actions to improve.",
  },
  {
    id: 3,
    // icon: <MdConnectWithoutContact color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Inclusion",
    text:
      "Customers without internet use can make submissions via SMS. After filling, the data is immediately sent to the server.",
  },
  {
    id: 4,
    // icon: <FaGg color="#0a1930" size={22} />,
    icon: <SiDatabricks color="#71ADF5" size={22} />,
    heading: "Notifications System",
    text: "Notify your tenants through notifications.",
  },
];
