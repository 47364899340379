import React from "react";
import wave from "../../assets/Slogan/sloganwave.svg";
import "./Wave.css"

const Wavecima = () => {
    return (
        <>
            <div className="wave">
                <img src={wave} />
            </div>

        </>
    );
};

export default Wavecima;
