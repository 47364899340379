import React from "react";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <>
      <div className="hero-container">
        <h1>Welcome to Speranza</h1>
        <p>What are you waiting for?</p>
        <div className="hero-btns">
         
        </div>
      </div>
    </>
  );
}

export default HeroSection;
