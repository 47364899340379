import React from "react";
import "./Footer.css";
import logo from "../../assets/logo.png";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="wavefooterbaixo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1F2631" fill-opacity="1" d="M0,288L60,293.3C120,299,240,309,360,298.7C480,288,600,256,720,250.7C840,245,960,267,1080,245.3C1200,224,1320,160,1380,128L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
      </div>
      <section id="footer">
        <div className="container footer">
          <div className="footer-box">
            <h4>About US</h4>
            <p>
              We are a company with consolidated experience in safety materials at
              work as well as the development of technological solutions.
            </p>
          </div>
          {/* <div className="footer-box">
            <h4>Support</h4>
            <div className="footer-links">
              <a href="#">&bull; Support 24 hours a day </a>
              <a href="#">&bull; Qualified technicians </a>
            </div>
          </div> */}
          <div className="footer-box">
            <h4>Contact Us</h4>
            <div className="footer-contact u-text-small">
              <p>
                <FaMapMarkerAlt /> &nbsp; Mozambique, Maputo, Alegria Av.
              </p>
              <p>
                <FaPhoneAlt /> &nbsp; Phone: 21000000
              </p>
              {/* <p>
              <FaFax /> &nbsp; Fax:
            </p> */}
              <p>
                <FaEnvelope /> &nbsp; Email: &nbsp; info@speranza.co.mz <br />
              </p>
              <p>
                <FaGlobe /> &nbsp; Website: www.speranza.co.mz
              </p>
            </div>
          </div>
          <div className="footer-box">
            <img src={logo} alt="logo" />
            {/* <p className="u-text-small">&copy; Copyright 2022. Speranza LDA</p> */}
          </div>
        </div>


      </section>
      <div className="footer-fim">
        <div className="footer-fim-esquerda">
          <p>To integrate is to inspire</p>
        </div>
        <div className="social-icons">
          <div className="social-icon">
            <FaFacebookF />
          </div>
          <div className="social-icon">
            <FaTwitter />
          </div>
          <div className="social-icon">
            <FaInstagram />
          </div>

        </div>
        <div className="footer-fim-direita">
          <p>©2022 - Speranza, LDA</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
