import React from 'react';
import AboutImg from '../../assets/twigg/Tw3.jpeg';
import styled from 'styled-components';
import "./AboutSection.css";
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export default function AboutSection() {
  return (
    <>
      <section id="about-section">
        <div class="about-left">
          <img src={AboutImg} width="350px" />
        </div>

        <div class="about-right">
          <h1>About Us</h1>
          <br />
          <h4>Our Story</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Debitis fugiat a dolorem at similique maxime dolorum dolore
            enim dicta voluptatibus, illum recusandae, vel optio tempore
            ipsum incidunt eum. Aspernatur, repellendus.illum recusandae,
            vel optio tempore ipsum incidunt eum. Aspernatur, repellendus.
            Aspernatur, repellendus.illum recusandae,
            vel optio tempore ipsum incidunt eum. Aspernatur, repellendus.
          </p>
          <div class="address">
            <ul>
              <li>
                <span class="address-logo">
                  <HomeIcon sx={{ fontSize: 24 }} />
                </span>
                <p>Address</p>
                <span class="saprater">:</span>
                <p>Av. da Alegria</p>
              </li>
              <li>
                <span class="address-logo">
                  <LocalPhoneIcon sx={{ fontSize: 20 }} />
                </span>
                <p>Phone</p>
                <span class="saprater">:</span>
                <p>+21 2212113411</p>
              </li>
              <li>
                <span class="address-logo">
                  <EmailIcon sx={{ fontSize: 20 }} />
                </span>
                <p>Email</p>
                <span class="saprater">:</span>
                <p>comercial@speranza.co.mz</p>
              </li>
            </ul>
          </div>
        </div>
      </section>

    </>

  );
}
