import React, { useEffect } from "react";
import "./Header.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
// import phoneHeader from "../../assets/phone-header-bg.png";
import Contactus from "../../assets/Contactus/Contactus.svg";
import { BsMouse } from "react-icons/bs";

import AOS from "aos";
import "aos/dist/aos.css";

const HeaderContacts = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="header">
      <div className="container header">
        <div className="header-left" data-aos="fade-right">
          <h1>
            <span>Talk to us</span>
          </h1>
          <h2>
            <span>We would be happy to help</span>
          </h2>

          <div className="descri">
            <p>Talk to us, and join our services and products now!</p>
          </div>
          <div className="header-cta">
            {/* <Button
              text={"Get Started"}
              btnClass={"btn-dark"}
              href={"#sendemail"}
            /> */}
            <Button text={"Talk to us Now"} btnClass={"btn-light"} href={"#"} />
          </div>
        </div>
        <div className="header-right" data-aos="fade-left">
          <img src={Contactus} alt="phone" />
        </div>
      </div>
    </section>
  );
};

export default HeaderContacts;
