import React from "react";
import "./Products.css";

const ProductsList = () => {
  return (
    <>
      <div id="cursos-list" class="about-section">
        <div class="inner-container">
          <h1>Our Products</h1>
          <p class="text">
            Check out our product list with regards to safety training below.
          </p>
          <ul className="u-text-small-cursos">
            <li>Boats</li>
            <li>Helmet</li>
            <li>Gloves</li>
            <li>Glasses</li>
            <li>Safety Vest</li>
          </ul>
        </div>
      </div>
      <div id="cursos-list" class="about-section2">
        <div class="inner-container2">
          <h1>Our Products</h1>
          <p class="text">
            Check out our product list with regards to safety training below.
          </p>
          <ul className="u-text-small-cursos">
            <li>Boats</li>
            <li>Helmet</li>
            <li>Gloves</li>
            <li>Glasses</li>
            <li>Safety Vest</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProductsList;
