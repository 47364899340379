import React from "react";
import "./Slogan.css";

const Slogan = () => {
  return (
    <>
      <div className="afastamento">
        <div className="slogan-geral">
          <div className="cima">
            <p>Making Security SIMPLE, and AFFORDABLE</p>
          </div>
          <div className="baixo">
            <p>Enjoy all our products and services for your company.</p>
          </div>
        </div>
      </div>
      <div className="wavebaixo">
        <br />
        <br />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#F48012" fill-opacity="1" d="M0,96L60,128C120,160,240,224,360,213.3C480,203,600,117,720,96C840,75,960,117,1080,117.3C1200,117,1320,75,1380,53.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
      </div>
    </>
  );
};

export default Slogan;
