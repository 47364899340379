import "./App.css";
import React from "react";
import {
  Navbar,
  Header,
  Features,
  Download,
  Subscribe,
  Faq,
  Footer,
} from "./components";
import Carrousel from "./components/carrousel/carrousel";
import Testimonial from "./components/Testimonials/Testimonials";
import Products from "./components/pages/Products/ProductsResume";
import Branding from "./components/Branding/Branding";
import Aboutus from "./components/Aboutus/Aboutus";
import HeroSection from "./components/Hero/HeroSection";
import AboutSection from "./components/OurIntro/AboutSection";
import Carroucel from "./components/OurIntro/Carroucel";

function App() {
  return (
    <>
      <header className="header-bg1">
        <Navbar />
      </header>
      <HeroSection />
      <AboutSection />
      <div className="home-titiles">
        <h1>Our Gallery</h1>
      </div>
      <br />
      <br />
      <br />
      <Carroucel />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="home-titiles">
        <h1>Some of our technologic solutions</h1>
        <h2>Check out some of our technological solutions</h2>
      </div>
      <Features data-aos="fade-up" />
      <Download />
      <Subscribe />
      <Faq />
      <Carrousel />
      <br />
      <br />
      <br />
      <div className="home-titiles">
        <h1>Testimonies</h1>
        <br />
        <hr />
        <br />
        <h2>Find out what people say about our Services</h2>
      </div>
      <Testimonial />
      <Footer />
    </>
  );
}

export default App;
