import React, { useEffect } from "react";
import "./Services.css";
import HeaderServices from "../../header/HeaderServices";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import TestimonialsSafety from "../../Testimonials/TestimonialsSafetyTrainings";
import HeaderTechno from "../../header/HeaderTechAre";
import Atendimento from "../../features/Atendimento";
import { makeStyles } from "@material-ui/core/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import { createTheme } from "@mui/material/styles";
import Features from "../../features/Features";
import ServicesDescription from "./ServicesDescription";
import Slogan from "../../Slogan/Slogan";
import Wavecima from "../../Waves/Wavecima";

const Services = () => {
  // const classes = useStyles();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <header className="header-bg1">
        <Navbar />
      </header>
      <br />
      <br />
      <br />
      <HeaderServices />
      <br />
      <br />
      <Wavecima />
      <Slogan />


      <div id="courses" class="horizontal-services-cursos">
        <h1> Our Courses </h1>
      </div>

      <ServicesDescription />

      <HeaderTechno />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Atendimento />
      <Features />
      {/* <div className="video-title">
        <h1>Testimonies</h1>
        <hr />
        <h2>Find out what people say about our courses</h2>
      </div> */}
      {/* <TestimonialsSafety /> */}

      <br />
      <br />

      <Footer />
    </>
  );
};

export default Services;
