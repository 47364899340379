import React, { useEffect } from "react";
import "./HeaderProducs.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import Shopping from "../../assets/headers/Shoping.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const HeaderProducts = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="headerr">
      <div className="container headerr">
        <div className="header-left" data-aos="fade-right">
          <h1>
            <span>Check out the best products for your company</span>
          </h1>
          <h2>
            <span>Check out our products</span>
          </h2>

          <div className="descri">
            <p>Check out the best products of Speranza for your company!</p>
          </div>
          <div className="header-cta">
            <Button
              text={"Buy Now"}
              btnClass={"btn-light"}
              href={"#products"}
            />
          </div>
        </div>
        <div className="header-right" data-aos="fade-left">
          <img src={Shopping} alt="phone" />
        </div>
      </div>
    </section>
  );
};

export default HeaderProducts;
