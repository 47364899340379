import React, { useEffect } from "react";
import "./Download.css";
import { FaApple, FaWindows } from "react-icons/fa";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import condometro from "../../assets/SoftwareProjects/condometro.png";
import { GrAndroid } from "react-icons/gr";
import { Condometro_Description } from "../features/data";
import { IconContext } from "react-icons";
import Feature from "../features/Feature";
import AOS from "aos";
import "aos/dist/aos.css";

const Download = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div>
      <div className="container features">
        <div className="title" data-aos="fade-up">
          <BsFillBookmarkStarFill color="orangered" size={30} />
          <h2>CONDOMETRO</h2>
          <p className="title">

            A condominium management app


          </p>
        </div>
        <div className="features-content">
          <div className="features-left" data-aos="fade-right">
            <div className="caracteristicas-condometro">
              <img src={condometro} alt="phone" />
            </div>
          </div>
          <div className="features-right" data-aos="fade-left">
            {Condometro_Description.map((feature) => (
              <Feature
                key={feature.id}
                icon={feature.icon}
                heading={feature.heading}
                text={feature.text}
              />
            ))}
          </div>
        </div>
      </div>
      <section id="download">
        <div className="container download" data-aos="fade-up">
          <h2>Condometro</h2>
          <p className="u-text-small">
            Condometro are available for download on all stores.
          </p>
          <IconContext.Provider value={{ size: "15" }}>
            <div className="download-icons">
              <div className="download-icon">
                <FaApple /> <p>ios</p>
              </div>
              <div className="download-icon">
                <GrAndroid /> <p>Android</p>
              </div>
              {/* <div className="download-icon">
              <FaWindows /> <p>Windows</p>
            </div> */}
            </div>
          </IconContext.Provider>
        </div>
      </section>
    </div>
  );
};

export default Download;
