import React, { useEffect } from "react";
import "./Products.css";
import HeaderProducts from "../../header/HeaderProducts";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import Agendamentos from "../../features/Agendamentos";
import ProductsList from "../../Aboutus/ProductsList";
import AOS from "aos";
import "aos/dist/aos.css";
import HeaderTechno from "../../header/HeaderTechAre";
import Condometro from "../../features/Condometro";
import Slogan from "../../Slogan/Slogan";
import Wavecima from "../../Waves/Wavecima";

const Products = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <header className="header-bg1">
        <Navbar />
      </header>

      <HeaderProducts />
      <br />
      <br />
      <br />
      <Wavecima />
      <Slogan />

      <div className="titulo-productos">
        <p>Our Products</p>
      </div>

      <ProductsList />
      <HeaderTechno />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Agendamentos />
      <Condometro />
      <Footer />
    </>
  );
};

export default Products;
