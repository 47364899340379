import React, { Component } from "react";
import "./Testimonials.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Person1 from "../../assets/Testemunhos/Testemunho2.png";
import Person2 from "../../assets/Testemunhos/Testemunho.png";


function TestimonialsSafety() {
  return (
    <>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img src={Person2} />
          <div className="myCarousel">
            <h3>Shirley Fultz</h3>
            <h4>Hygiene and Safety Tech</h4>
            <p>
              The courses given by speranza are excellent, given by qualified
              and trained professionals.
            </p>
          </div>
        </div>

        <div>
          <img src={Person1} />
          <div className="myCarousel">
            <h3>Daniel Keystone</h3>
            <h4>Hygiene and Safety Tech</h4>
            <p>I recommend Speranza courses are very good.</p>
          </div>
        </div>

        <div>
          <img src={Person2} />
          <div className="myCarousel">
            <h3>Theo Sorel</h3>
            <h4>Hygiene and Safety Tech</h4>
            <p>
              The courses given by speranza are excellent, given by qualified
              and trained professionals, I recommend Speranza courses are very
              good.
            </p>
          </div>
        </div>
      </Carousel>
    </>
  );
}

export default TestimonialsSafety;