import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import About from "./components/pages/About/About";
import Contactus from "./components/pages/Contactus/Contactus";
import Products from "./components/pages/Products/Products";
import Services from "./components/pages/Services/Services";
import SoftwareArea from "./components/pages/SoftwareDevlopment/SoftwareDev"
import { BrowserRouter, Route, Routes } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/about' element={<About />} />
      <Route path='/services' element={<Services />} />
      <Route path='/products' element={<Products />} />
      <Route path='/contactus' element={<Contactus />} />
      <Route path='/Softwaredevlopmentarea' element={<SoftwareArea />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
