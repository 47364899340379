import React from "react";
import "./Header.css";
import parceiro1_twigg from "../../assets/clientes/TwiggLogo.png";
import parceiro2_mchimoio from "../../assets/clientes/logo-chimoio.png";
import parceiro3_mcmaputo from "../../assets/clientes/MunMaputo.jpg";
import Carousel from "react-elastic-carousel";

const Carrousel = () => {
  return (
    <section id="header">
      <div className="parceiros">
        <h1>Our Clients</h1>
        <p>
          Check out some of our clients below.
        </p>
      </div>
      <div>
        <Carousel
          className="parceiros"
          itemsToShow={3}
          enableAutoPlay
          autoPlaySpeed={2500}
        >
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
          <img src={parceiro1_twigg} />
          <img src={parceiro2_mchimoio} />
          <img src={parceiro3_mcmaputo} />
        </Carousel>
      </div>
    </section>
  );
};

export default Carrousel;
