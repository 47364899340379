import React, { useEffect } from "react";
import "./HeaderTrainings.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import Safetraining from "../../assets/ServicesTrainings/Employee.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const HeaderServices = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="headerr">
      <div className="container headerr">
        <div className="header-left" data-aos="fade-right">
          <h1>
            <span>Training Services</span>
          </h1>
          <h2>Check out our training services</h2>

          <div className="descri">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              mi ante, convallis quis turpis nec, blandit mattis turpis.
            </p>
          </div>
          <div className="header-cta">
            <Button
              text={"Our courses"}
              btnClass={"btn-light"}
              href={"#cursos-list"}
            />
          </div>
        </div>
        <div className="header-right" data-aos="fade-left">
          <img src={Safetraining} alt="phone" />
        </div>
      </div>
    </section>
  );
};

export default HeaderServices;
