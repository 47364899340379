import React, { useEffect } from "react";
import "./Features.css";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import { FaLaptopCode } from "react-icons/fa";
import condometro from "../../assets/SoftwareProjects/condometro.png";
import Agendamentos from "../../assets/SoftwareProjects/Agendamentos.png";
import Feature from "./Feature";
import { Condometro_Description, Bages } from "./data";

import AOS from "aos";
import "aos/dist/aos.css";

const FeactureAllProjects = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="features">
      <div className="container features">
        <div className="title" data-aos="fade-up">
          <FaLaptopCode color="#fc5412" size={50} />

          <h2>SILICA</h2>
          <p className="u-text-small">
            Badge Printing and Training Scheduling Platform
          </p>
        </div>
        <div className="features-content">
          <div className="features-left" data-aos="fade-right">
            <div className="caracteristicas">
              <img src={Agendamentos} alt="phone" />
            </div>
          </div>
          <div className="features-right" data-aos="fade-left">
            {Bages.map((feature) => (
              <Feature
                key={feature.id}
                icon={feature.icon}
                heading={feature.heading}
                text={feature.text}
              />
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="container features">
        <div className="title" data-aos="fade-up">
          <BsFillBookmarkStarFill color="orangered" size={30} />
          <h2>CONDOMETRO</h2>
          <p className="u-text-small">A condominium management app</p>
        </div>
        <div className="features-content">
          <div className="features-left" data-aos="fade-right">
            <div className="caracteristicas-condometro">
              <img src={condometro} alt="phone" />
            </div>
          </div>
          <div className="features-right" data-aos="fade-left">
            {Condometro_Description.map((feature) => (
              <Feature
                key={feature.id}
                icon={feature.icon}
                heading={feature.heading}
                text={feature.text}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeactureAllProjects;
