import React, { useEffect } from "react";
import "./Products.css";
import HeaderProducts from "../../header/HeaderProducts";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import Paper from "@mui/material/Paper";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Toolbar,
} from "@material-ui/core";

import AOS from "aos";
import "aos/dist/aos.css";

import Button from "../../UI/Button/Button";

// import { makeStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

//Equipamento/Pruductos
import Botas from "../../../assets/Products/boats.png";
import Capacete from "../../../assets/Products/capacete.jpg";
import Luvas from "../../../assets/Products/gloves.jpg";
import Oculos from "../../../assets/Products/oculos.jpeg";
import whatsapp from "../../../assets/Products/whatsapus.png";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "100px",
  },
});

const Products = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
        overflow-x="hidden"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={8}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <Toolbar />
                  <div className="imagens-products">
                    <img src={Capacete} />
                  </div>
                </CardMedia>
              </CardActionArea>{" "}
              <div className="footer-card">
                <CardContent>
                  <Typography style={{ color: "white" }} variant="h5">
                    {" "}
                    Helmet{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.65)",
                      fontSize: "14px",
                    }}
                    variant="subtitle1"
                  >
                    Have the best safety equipment for your employees <br />
                    <br />
                    <div className="header-cta">
                      <Button
                        text={"Buy Now"}
                        btnClass={"btn-dark"}
                        href={"#"}
                      />
                      <Button
                        text={"See Details"}
                        btnClass={"btn-light"}
                        href={"#"}
                      />
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={8}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <Toolbar />
                  <div className="imagens-products">
                    <img src={Capacete} />
                  </div>
                </CardMedia>
              </CardActionArea>{" "}
              <div className="footer-card">
                <CardContent>
                  <Typography style={{ color: "white" }} variant="h5">
                    {" "}
                    Helmet{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.65)",
                      fontSize: "14px",
                    }}
                    variant="subtitle1"
                  >
                    Have the best safety equipment for your employees <br />
                    <br />
                    <div className="header-cta">
                      <Button
                        text={"Buy Now"}
                        btnClass={"btn-dark"}
                        href={"#"}
                      />
                      <Button
                        text={"See Details"}
                        btnClass={"btn-light"}
                        href={"#"}
                      />
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={8}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <Toolbar />
                  <div className="imagens-products">
                    <img src={Capacete} />
                  </div>
                </CardMedia>
              </CardActionArea>{" "}
              <div className="footer-card">
                <CardContent>
                  <Typography style={{ color: "white" }} variant="h5">
                    {" "}
                    Helmet{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.65)",
                      fontSize: "14px",
                    }}
                    variant="subtitle1"
                  >
                    Have the best safety equipment for your employees <br />
                    <br />
                    <div className="header-cta">
                      <Button
                        text={"Buy Now"}
                        btnClass={"btn-dark"}
                        href={"#"}
                      />
                      <Button
                        text={"See Details"}
                        btnClass={"btn-light"}
                        href={"#"}
                      />
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={8}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <Toolbar />
                  <div className="imagens-products">
                    <img src={Capacete} />
                  </div>
                </CardMedia>
              </CardActionArea>{" "}
              <div className="footer-card">
                <CardContent>
                  <Typography style={{ color: "white" }} variant="h5">
                    {" "}
                    Helmet{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.65)",
                      fontSize: "14px",
                    }}
                    variant="subtitle1"
                  >
                    Have the best safety equipment for your employees <br />
                    <br />
                    <div className="header-cta">
                      <Button
                        text={"Buy Now"}
                        btnClass={"btn-dark"}
                        href={"#"}
                      />
                      <Button
                        text={"See Details"}
                        btnClass={"btn-light"}
                        href={"#"}
                      />
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={8}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <Toolbar />
                  <div className="imagens-products">
                    <img src={Capacete} />
                  </div>
                </CardMedia>
              </CardActionArea>{" "}
              <div className="footer-card">
                <CardContent>
                  <Typography style={{ color: "white" }} variant="h5">
                    {" "}
                    Helmet{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.65)",
                      fontSize: "14px",
                    }}
                    variant="subtitle1"
                  >
                    Have the best safety equipment for your employees <br />
                    <br />
                    <div className="header-cta">
                      <Button
                        text={"Buy Now"}
                        btnClass={"btn-dark"}
                        href={"#"}
                      />
                      <Button
                        text={"See Details"}
                        btnClass={"btn-light"}
                        href={"#"}
                      />
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={8}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <Toolbar />
                  <div className="imagens-products">
                    <img src={Capacete} />
                  </div>
                </CardMedia>
              </CardActionArea>{" "}
              <div className="footer-card">
                <CardContent>
                  <Typography style={{ color: "white" }} variant="h5">
                    {" "}
                    Helmet{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(255, 255, 255, 0.65)",
                      fontSize: "14px",
                    }}
                    variant="subtitle1"
                  >
                    Have the best safety equipment for your employees <br />
                    <br />
                    <div className="header-cta">
                      <Button
                        text={"Buy Now"}
                        btnClass={"btn-dark"}
                        href={"#"}
                      />
                      <Button
                        text={"See Details"}
                        btnClass={"btn-light"}
                        href={"#"}
                      />
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Products;
