export const questions = [
  {
    id: 1,
    title: "Our Mission",
    answer:
      "Streamline customer service through technological solutions that guide informed decision-making",
  },
  {
    id: 2,
    title: "Our Vision",
    answer:
      "Become a reference for good practices related to participatory management in public and private institutions.",
  },
  {
    id: 3,
    title: "Our Values",
    answer: "Ethic; Market orientation; Innovation; Inclusion..",
  },
];
