import React, { useEffect } from "react";
import "./About.css";
import HeaderAbout from "../../header/HeaderAbout";
import HeaderContacts from "../../header/HeaderContacts";
import { useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import emailjs from "@emailjs/browser";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import headerwave from "../../../assets/Slogan/wave.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Aboutus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "SperanzaDemo",
        "template_ipfzrel",
        form.form,
        "vj8jyG5jRgqpqwzKz"
      )
      .then(
        (result) => {
          alert("Mesnagem enviada com Sucesso!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <header className="header-bg1">
        <Navbar />
      </header>
      <header className="header-bg2">
        <HeaderAbout />
      </header>

      <img src={headerwave} />
      <div className="video-title">
        <h1>Why choose us?</h1>
        <hr />
        <h2>Watch our Story Below</h2>
      </div>
      <div id="vid" class="embed-youtube">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/_hZDatPqcUg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <br />
      </div>
      <div className="about-video">
        <p>About Speranza LDA</p>
      </div>
      <br />
      <br />

      <header className="header-bg2">
        <HeaderContacts />
      </header>
      <div id="sendemail" class="contact-wrap">
        <div class="contact-in">
          <h1>Contact Info</h1>
          <h2>
            {/* <i class="fa fa-phone" aria-hidden="true"></i> */}
            Phone
          </h2>
          <p>21000000</p>
          <h2>
            {/* <i class="fa fa-envelope" aria-hidden="true"></i>  */}
            Email
          </h2>
          <p>info@speranza.co.mz</p>
          <h2>
            {/* <i class="fa fa-map-marker" aria-hidden="true"></i>  */}
            Address
          </h2>
          <p>Mozambique, Maputo</p>
          <ul>
            <li>
              <a href="#">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="#">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href="#">
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>

        <div class="contact-in">
          <h1>Send a Message</h1>
          <form onSubmit={sendEmail}>
            <input
              type="text"
              placeholder="Full Name"
              name="name"
              class="contact-in-input"
            />
            <input
              type="text"
              placeholder="Email"
              name="email"
              class="contact-in-input"
            />
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              class="contact-in-input"
            />
            <textarea
              placeholder="Message"
              name="message"
              class="contact-in-textarea"
            ></textarea>
            <input type="submit" value="SUBMIT" class="contact-in-btn" />
          </form>
        </div>
        <div class="contact-in">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3587.0713574450115!2d32.58524400000001!
            3d-25.9657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb8d7840f99a461e4!2sCentro%20De%20Exames%20Medicos%20(CEM)
            !5e0!3m2!1sen!2sin!4v1649020677752!5m2!1sen!2sin"
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
