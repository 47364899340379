import React, { Component } from "react";
import "./Testimonials.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Person1 from "../../assets/Testemunhos/Testemunho2.png";
import Person2 from "../../assets/Testemunhos/Testemunho.png";

function TestimonialsTecnology() {
  return (
    <>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img src={Person1} />
          <div className="myCarousel">
            <h3>Sebastiao Cumbana</h3>
            <h4>Software Developer</h4>
            <p>
              I recommend the condometro app for everyone, quite simple and easy
              to use and the ussd solution is quite simple to use and practical,
              it responds to the needs of citizens.
            </p>
          </div>
        </div>

        <div>
          <img src={Person2} />
          <div className="myCarousel">
            <h3>Daniel Sigauque</h3>
            <h4>IT</h4>
            <p>
              The ussd solution is quite simple to use and practical, it
              responds to the needs of citizens.
            </p>
          </div>
        </div>

        <div>
          <img src={Person1} />
          <div className="myCarousel">
            <h3>Bernardo Andre Sitoe</h3>
            <h4>Commercial manager</h4>
            <p>
              I recommend the condometro app for everyone, quite simple and easy
              to use.
            </p>
          </div>
        </div>
      </Carousel>
    </>
  );
}

export default TestimonialsTecnology;
