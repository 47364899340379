import React, { useEffect } from "react";
import "./SoftwareDev.css";
import HeaderTechno from "../../header/HeaderTechAre";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import Features from "../../features/Features";
import FeatureAllProjects from "../../features/FeactureAllProjects";

import { makeStyles } from "@material-ui/core/styles";
import AOS from "aos";
import "aos/dist/aos.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import styled from "styled-components";
import TestimonialsTecnology from "../../Testimonials/TestimonialsTecnoArea";

const Container = styled.main`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
`;

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "100px",
  },
});

const Services = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <header className="header-bg1">
        <Navbar />
      </header>
      <header className="header-bg2">
        <HeaderTechno />
      </header>
      <div class="horizontal-services">
        <h3> Technologic solutions </h3>
      </div>
      <Features data-aos="fade-up" />
      <FeatureAllProjects data-aos="fade-up" />

      <br />
      <div className="video-title">
        <h1>Testimonies</h1>
        <hr />
        <h2>Find out what people say about our technological solutions</h2>
      </div>
      <TestimonialsTecnology />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Services;
