import IMG1 from "../../assets/Testemunhos/Testemunho.png"

const people = [
    {
        id: 1,
        image: IMG1,
        name: "Haryy Stamper",
        title: "Hygiene and Safety Tech",
        quote:
            "The courses given by speranza are excellent, given by qualified            and trained professionals, I recommend Speranza courses are very          good.",
    },
    {
        id: 2,
        image: IMG1,
        name: "James Gun",
        title: "Project Manager",
        quote:
            "I recommend Speranza courses are very good. I recommend the condometro app for everyone, quite simple and easy to use ",
    },
    {
        id: 3,
        image: IMG1,
        name: "Michael Mosely",
        title: "Hygiene and Safety Tech",
        quote:
            "The courses given by speranza are excellent, given by qualified and trained professionals, I recommend Speranza courses are very good.",
    },
    {
        id: 4,
        image: IMG1,
        name: "James Van",
        title: "Vice President",
        quote:
            " The ussd solution is quite simple to use and practical, it responds to the needs of citizens.",
    },
];

export default people;
