import React from "react";
import "./About.css";

const Aboutus = () => {
  const data = [
    {
      title: "Who we Are And What we Do",
      desc1:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde possimus quaerat quam dolorum ipsa laboriosam, repudia illum amet inventore facilis, eius libero.",
      desc2:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
      desc3:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      cover: "./assets/about.jpg",
    },
  ];
  return (
    <div id="cursos-list" class="about-section-products">
      <div class="inner-container">
        <h1>Our courses</h1>
        <p class="text">See below for a list of some of our courses.</p>
        <ul className="u-text-small-cursos">
          <li>Folklift Operation VOC</li>
          <li>Folklift Operation</li>
          <li>Light Duty Vehicle</li>
          <li>Telehandler Operation</li>
          <li>Work at Height</li>
          <li>LOTO</li>
          <li>Confined Spaces</li>
          <li>Full Induction</li>
          <li>Overhead Crane VOC</li>
          <li>Chemical Products</li>
        </ul>
      </div>
    </div>
  );
};

export default Aboutus;
