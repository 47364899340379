import React, { useEffect } from "react";
import "./Header.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";

import Aboutus from "../../assets/Aboutus/Aboutus.svg";

import AOS from "aos";
import "aos/dist/aos.css";

const HeaderAbout = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <section id="header">
        <div className="container header">
          <div className="header-left" data-aos="fade-right">
            <h1>
              <span>Learn more about us</span>
            </h1>
            <h2>
              <span>Learn more about us</span>
            </h2>

            <div className="descri">
              <p>
                Learn more about us below, our history , products and our
                services!
              </p>
            </div>
            <div className="header-cta">
              <Button text={"Get Started"} btnClass={"btn-dark"} href={"#vid"} />
            </div>
          </div>
          <div className="header-right" data-aos="fade-left">
            <img src={Aboutus} alt="phone" />
          </div>
        </div>

      </section>
      
    </>

  );
};

export default HeaderAbout;
