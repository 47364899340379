import React, { useEffect } from "react";
import "./Features.css";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import phoneUssd from "../../assets/SoftwareProjects/USSD.png";
import Feature from "./Feature";
import { FeatureList } from "./data";

import { FaLaptopCode } from "react-icons/fa";

import AOS from "aos";
import "aos/dist/aos.css";

const Features = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="software">
      <div className="container features">
        <div className="title" data-aos="fade-up">
          <GiSmartphone color="orangered" size={50} />
          <h2>e-STM - USSD </h2>
          <p className="u-text-small">The Municipal Tax System (e-stm)</p>
        </div>
        <div className="features-content">
          <div className="features-left" data-aos="fade-right">
            <img src={phoneUssd} alt="phone" />
          </div>
          <div className="features-right" data-aos="fade-left">
            {FeatureList.map((feature) => (
              <Feature
                key={feature.id}
                icon={feature.icon}
                heading={feature.heading}
                text={feature.text}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
